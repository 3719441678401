






















































import {Component, Vue} from 'vue-property-decorator'
import {PageResultDto} from '@/framework/models/ResultDto';
import {CourseOrderDto, GetListCourseOrderDto} from '@/framework/models/CourseOrder';
import CourseOrderApi from '@/api/course/courseOrder';

@Component({})
export default class CourseOrder extends Vue {
  private api = new CourseOrderApi();
  private listQuery = new GetListCourseOrderDto();
  private tableData: PageResultDto<CourseOrderDto> = {
    items: new Array<CourseOrderDto>(),
    totalCount: 0
  };

  private pagination = {
    currentPage: 1
  }

  private async activated() {
    await this.getList();
  }

  private async getList() {
    this.tableData = await this.api.getListAsync(this.listQuery);
  }

  private async currentChange(currentPage: number) {
    this.pagination.currentPage = currentPage
    this.listQuery.skipCount = (currentPage - 1) * this.listQuery.maxResultCount
    await this.getList()
  }

  private async handleFuKuan(row: any) {
    await this.api.payment(row.id);
    await this.getList()
  }
}
