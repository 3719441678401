import {ExtensibleEntityDto} from './ExtensibleEntityDto'
import {PagedAndSortedResultRequestDto} from './PagedAndSortedResultRequestDto'
import {Guid} from 'guid-typescript';

export interface CourseOrderDto extends ExtensibleEntityDto <Guid> {
  orderCode: string
  courseName: string
  remark: string
  price: number
  discountPrice: number
  courseTime: number
  orderState: number
  creator: string
  creationTime: string
}

export class CreateOrUpdateCourseOrderDto {
  public orderCode: string = ''
  public courseName: string = ''
  public remark: string = ''
  public price: number | undefined
  public discountPrice: number | undefined
  public courseTime: number | undefined
  public orderState: number | undefined
  public creator: string = ''
  public creationTime: string = ''
}

export class GetListCourseOrderDto extends PagedAndSortedResultRequestDto {
  public filter: string = ''
}
